import NextImage from "next/image";
import { basePath } from "../../lib/basePath";

const basePathLoader = ({ src, width, quality }) => {
  const encoded = encodeURIComponent(`${basePath}${src}`);
  return `${basePath}/_next/image?url=${encoded}&w=${width}&q=${quality || 75}`;
};

const Image = (props) => <NextImage loader={basePathLoader} {...props} />;

export default Image;
