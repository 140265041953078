import styled from "styled-components";
import PropTypes from "prop-types";

export const RotatableSvg = styled.svg(({ direction }) => ({
  fill: "transparent",
  transform: direction === "left" ? "rotate(180deg)" : null
}));

RotatableSvg.propTypes = {
  direction: PropTypes.oneOf(["left", "right"])
};
