import styled from "styled-components";
import clsx from "clsx";
import PropTypes from "prop-types";
import { RotatableSvg } from "@primitives/rotatableSvg";
import Link from "next/link";
import { forwardRef } from "react";

export const Button = styled.button(({ alternate, theme }) => ({
  alignItems: "center",
  backgroundColor: alternate ? theme.colors.white : theme.colors.black,
  borderColor: alternate ? theme.colors.white : theme.colors.black,
  borderRadius: 2,
  borderStyle: "solid",
  borderWidth: 1,
  color: alternate ? theme.colors.black : theme.colors.white,
  cursor: "pointer",
  display: "inline-flex",
  fontFamily: theme.fonts.poppins,
  fontSize: 14,
  fontWeight: 700,
  justifyContent: "center",
  lineHeight: 1.5,
  paddingBottom: 7,
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 7,
  whiteSpace: "nowrap",

  "&:hover": {
    backgroundColor: alternate ? theme.colors.gray2 : theme.colors.gray9,
    borderColor: alternate ? theme.colors.gray2 : theme.colors.gray9
  },

  "&:focus": {
    backgroundColor: alternate ? theme.colors.gray2 : theme.colors.gray9,
    borderColor: theme.colors.white,
    outline: `1px solid ${theme.colors.blue}`
  },

  "&:active": {
    backgroundColor: alternate ? theme.colors.gray3 : theme.colors.gray11,
    borderColor: alternate ? theme.colors.gray3 : theme.colors.gray11,
    outline: "none"
  },

  "&:disabled": {
    backgroundColor: theme.colors.gray4,
    borderColor: theme.colors.gray4,
    color: theme.colors.gray7,
    cursor: "default"
  }
}));

Button.propTypes = {
  alternate: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

const ButtonAsLink = styled(Button).attrs({ as: "a" })({});

export const LinkButton = forwardRef(({ external, href, ...props }, ref) => {
  const externalProps = external ? { rel: "noreferrer", target: "_blank" } : {};

  return (
    <Link href={href} passHref>
      <ButtonAsLink {...externalProps} {...props} ref={ref} />
    </Link>
  );
});

LinkButton.displayName = "LinkButton";

LinkButton.propTypes = {
  alternate: PropTypes.bool,
  className: PropTypes.string,
  external: PropTypes.bool,
  href: PropTypes.string.isRequired
};

export const TextButton = ({ className, ...props }) => (
  <button
    className={clsx(
      "brad-2 font--body-sm hover-bg-blue-25 px-md py-0 inline-flex items-center justify-center nowrap transition-colors duration-200 ease-in-out pointer",
      className
    )}
    {...props}
  />
);

TextButton.propTypes = {
  className: PropTypes.string
};

export const CircleArrow = ({ className, direction = "right" }) => {
  return (
    <RotatableSvg
      className={className}
      direction={direction}
      fill="currentColor"
      height="50"
      viewBox="0 0 50 50"
      width="50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="24.5" stroke="currentColor" />
      <path
        d="M31.3536 25.3536C31.5488 25.1583 31.5488 24.8417 31.3536 24.6464L28.1716 21.4645C27.9763 21.2692 27.6597 21.2692 27.4645 21.4645C27.2692 21.6597 27.2692 21.9763 27.4645 22.1716L30.2929 25L27.4645 27.8284C27.2692 28.0237 27.2692 28.3403 27.4645 28.5355C27.6597 28.7308 27.9763 28.7308 28.1716 28.5355L31.3536 25.3536ZM19 25.5L31 25.5L31 24.5L19 24.5L19 25.5Z"
        fill="currentColor"
      />
    </RotatableSvg>
  );
};

CircleArrow.propTypes = {
  className: PropTypes.string,
  direction: RotatableSvg.propTypes.direction
};

export const Arrow = ({ className, direction = "right" }) => {
  return (
    <RotatableSvg
      className={className}
      direction={direction}
      fill="currentColor"
      width="22"
      height="14"
      viewBox="0 0 22 14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20.4443 7L-0.000107288 7" stroke="currentColor" />
      <path d="M14.4443 13L20.4443 7L14.4443 1" stroke="currentColor" />
    </RotatableSvg>
  );
};

Arrow.propTypes = {
  className: PropTypes.string,
  direction: RotatableSvg.propTypes.direction
};

const Bar = styled.span({
  height: "2px",
  transition: "all 0.3s linear",
  transformOrigin: "2px"
});

const BurgerTop = styled(Bar)({
  transform: ({ mode }) =>
    mode === "close"
      ? "translate(4px, -2px) rotate(45deg)"
      : "translate(0, 0) rotate(0)"
});

const BurgerMiddle = styled(Bar)({
  opacity: ({ mode }) => (mode === "close" ? 0 : 1)
});

const BurgerBottom = styled(Bar)({
  transform: ({ mode }) =>
    mode === "close"
      ? "translate(4px, -2px) rotate(-45deg)"
      : "translate(0, 0) rotate(0)"
});

export const Hamburger = ({ mode, onClick }) => (
  <button
    className="flex justify-between flex-column font--body-lg h-xxs lh-1 pointer pa-0"
    onClick={onClick}
  >
    <BurgerTop className="bg-black db w-xs" mode={mode} />
    <BurgerMiddle className="bg-black db w-xs" mode={mode} />
    <BurgerBottom className="bg-black db w-xs" mode={mode} />
  </button>
);

Hamburger.propTypes = {
  mode: PropTypes.oneOf(["hamburger", "close"]),
  onClick: PropTypes.func.isRequired
};
